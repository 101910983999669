<template>
  <CSidebar
    position="fixed"
    class="sidebar-color"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      event =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <nav>
      <AppSidebarNav />
    </nav>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup () {
    const store = useStore()

    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  color: red;
}
.sidebar-color {
  background-color: green !important;
}
</style>
