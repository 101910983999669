<template>
  <CFooter class="footer">
    <CContainer fluid class="p-0">
      <svg viewBox="0 0 1825 624" id="background">
        <path
          fill="#FFFFFF"
          fill-opacity="1"
          d="M0 0L1825 0L1825 530L0 530Z"
        ></path>
        <path
          fill="#3b4054"
          fill-opacity="1"
          d="M0 447.53 119.29 450 230.47 449.65 332.82 444.71 429.18 433.06Q449.18 430.37 470.47 426.71 493.01 422.78 513.88 418.24A453.49 453.49 0 00558.35 408 450.12 450.12 0 00602.82 392.82C613.37 389.56 624.6 385.61 636.35 380.82A410.61 410.61 0 00678 361.06 332.31 332.31 0 00711.53 338.47 330.42 330.42 0 00738.35 315.53L783.88 255.18 838.24 171.18C842.82 162.77 848.11 153.11 852.71 144.71Q862.71 126.34 872.82 108L895.41 71.65A141.89 141.89 0 01956.47 21.18 165.09 165.09 0 011013.65 5.29Q1034.37 3.16 1056.71 1.76 1081.06.29 1103.65 0L1213.06.71 1305.18 6 1402.24 11.65 1521.88 24.35 1646.12 37.06 1789.76 54 1825 58.24V824H0Z"
        ></path>
        <path
          fill="#2e3142"
          fill-opacity="1"
          d="M1825 124.77C1817.1 125.26 1805.37 125.77 1791.11 125.71 1777.05 125.62 1765.7 124.87 1743.59 123.36 1730.05 122.43 1715.76 121.45 1696.53 119.59 1680.09 118.01 1668.67 116.59 1646.64 113.95 1627.3 111.6 1615.4 110.15 1599.11 107.83 1574.38 104.3 1573.65 103.61 1547.35 99.83 1524.83 96.59 1525.79 97.15 1502.17 93.71 1483.93 91.05 1482.05 90.53 1458.88 87.12 1440.07 84.36 1439.66 84.45 1422.64 81.95 1393.64 77.68 1393.26 77.23 1382.17 75.83 1364.36 73.58 1362.62 74.39 1330.41 72.06 1296.59 69.62 1296.49 68.57 1268.76 66.89 1261.64 66.45 1244.04 65.46 1221.23 65 1205.48 64.69 1192.77 64.44 1175.59 65 1146.17 66 1124.36 68.64 1119.59 69.24A478 478 0 001070.17 77.71 362.39 362.39 0 001019.8 92.77C1007.8 97.32 998.86 101.52 993.45 104.06 980 110.37 973.32 113.53 969 115A140.16 140.16 0 00949 126C936 137 941 132 932 140 910 158 902 176 906 170 901 179 896 188 888.56 207.17 881 226 877 236 874.86 241.05 864.72 256.39 864.23 254.41 849.45 275.41 837.26 292.71 838.23 293.15 829.68 304.11A342.21 342.21 0 01806.15 330.94C799.35 338.08 794.88 342.29 790.62 346A112 112 0 01761.45 365.29C759.58 366.2 753.69 369.03 738.86 374.7 732.5 377.13 726.92 379.27 719.56 381.76 713.4 383.85 707.56 385.64 685.68 391.64 667.44 396.64 658.32 399.13 653.68 400.11 641.6 402.67 640.03 402.19 620.74 405.29 595.33 409.37 597.74 410.29 579.33 412.82 566.93 414.55 564.59 414.33 539.33 417.53 534.75 418.1 527.82 418.98 521.45 419.88 499.61 422.95 487.45 425.82 471.56 428.82 447.92 433.29 429.89 435.45 406.62 438.23 394.62 439.67 383.54 440.76 361.45 442.94S328.33 446.17 315.8 447.17C292.08 449.01 274.8 449.63 251.8 450.47 234.49 451.09 212.8 451.68 187.8 451.88 167.52 452.04 168.18 451.76 139.8 451.88 108.92 452 108.8 452.33 82.39 452.35 52.46 452.35 37.74 451.97 24.98 451.41 14.58 450.95 5.98 450.41 0 450V864H1825Z"
        ></path>
        <path
          fill="#4f5269"
          fill-opacity="1"
          d="M0 447.53 119.29 450 230.47 449.65 332.82 444.71 429.18 433.06Q449.18 430.37 470.47 426.71 493.01 422.78 513.88 418.24A453.49 453.49 0 00558.35 408 450.12 450.12 0 00602.82 392.82C613.37 389.56 624.6 385.61 636.35 380.82A410.61 410.61 0 00678 361.06 332.31 332.31 0 00711.53 338.47 330.42 330.42 0 00738.35 315.53L783.88 255.18 838.24 171.18C842.82 162.77 848.11 153.11 852.71 144.71 874 160 876 169 892.53 208.83 864.72 256.39 864.23 254.41 849.45 275.41 837.26 292.71 838.23 293.15 829.68 304.11A342.21 342.21 0 01806.15 330.94C799.35 338.08 794.88 342.29 790.62 346A112 112 0 01761.45 365.29C759.58 366.2 753.69 369.03 738.86 374.7 732.5 377.13 726.92 379.27 719.56 381.76 713.4 383.85 707.56 385.64 685.68 391.64 667.44 396.64 658.32 399.13 653.68 400.11 641.6 402.67 640.03 402.19 620.74 405.29 595.33 409.37 597.74 410.29 579.33 412.82 566.93 414.55 564.59 414.33 539.33 417.53 534.75 418.1 527.82 418.98 521.45 419.88 499.61 422.95 487.45 425.82 471.56 428.82 447.92 433.29 429.89 435.45 406.62 438.23 394.62 439.67 383.54 440.76 361.45 442.94S328.33 446.17 315.8 447.17C292.08 449.01 274.8 449.63 251.8 450.47 234.49 451.09 212.8 451.68 187.8 451.88 167.52 452.04 168.18 451.76 139.8 451.88 108.92 452 108.8 452.33 82.39 452.35 52.46 452.35 37.74 451.97 24.98 451.41 14.58 450.95 5.98 450.41 0 450"
        ></path>
        <path
          fill="#3b4054"
          fill-opacity="1"
          d="M0 450Q28.89 450.12 58.59 450.71 84.66 451.24 110.12 452.12 155.36 452.34 202.59 451.42 247.59 450.51 290.82 448.59C319.44 446.14 343.67 443.95 362.12 442.24 410.25 437.79 424.12 436.18 436.94 434.47 461.24 431.26 481.18 427.82 494.82 425.3Q525.82 419.45 557.65 414 616 404.04 672 396.36A334 334 0 00744.71 370.95 333.61 333.61 0 00801.88 335.65C806.68 329.83 813.88 320.77 822.27 309.25 832.78 294.75 831.42 295.1 841.88 280.47 852.14 266.12 866.88 247.01 872.35 237.77 875.18 233 878.35 227.53 878.35 227.53 879.57 225.44 880.44 223.93 881.41 222.24 888.26 210.24 889.77 208.56 888.53 206.83 892.11 206.92 892.53 207.23 895.5 212.36 898.64 219.6 901.78 222.1 906.35 228.12 909.27 231.95 912.96 238.81 920.24 252.36 928.6 267.94 932.78 275.73 936.35 283.65 940.11 291.99 939.29 291.17 947.06 309.3 952.5 322 954.63 326.44 957.18 331.18A198.6 198.6 0 00967.53 348.36C968.69 350.07 971.96 354.86 976.71 360.83A150.14 150.14 0 00992 378 113.87 113.87 0 001008 391 119.53 119.53 0 001024.71 400.17 126.41 126.41 0 001045.41 408.17 116.9 116.9 0 001071.29 412.17 170.57 170.57 0 001104.47 410.76C1116.16 409.17 1124.71 406.85 1134.59 404.17 1144.06 401.6 1150.89 399.32 1159.53 396.41 1188 386.79 1211.4 378.56 1214.82 377.35 1243.58 367.22 1245.88 366.35 1254.59 363.47 1266.06 359.66 1277.59 355.84 1293.18 351.47 1301.62 349.09 1313.33 345.82 1328.47 342.47 1336.66 340.69 1353.11 337.16 1373.88 334.71 1407.21 330.79 1433.1 331.86 1449.18 332.59A395.51 395.51 0 011502.82 338C1508.08 338.92 1523.76 341.78 1543.76 347.42 1563.93 353.1 1563.91 354.68 1584.76 360.36 1597.27 363.77 1599.85 363.9 1622.87 369.77 1635.73 373.05 1644.41 375.43 1648.76 376.59 1681.58 385.41 1718 392.46 1747.76 398.24 1767.16 402 1792.1 406.6 1821.41 411.42V864H0Z"
        ></path>
        <image
          class="img-fluid"
          href="@/assets/images/logo_claro.png"
          alt="Logo Copla"
          x="1040"
          y="150"
          width="300"
          id="logo"
        />
        <image
          class="img-fluid mt-3 me-3"
          href="@/assets/images/Twitter-logo.svg"
          alt="Logo Twitter"
          x="1050"
          y="250"
          width="50px"
          height="50px"
          id="twitter"
        />
        <image
          class="img-fluid mt-3 me-3"
          href="@/assets/images/YouTube.svg"
          alt="Logo Youtube"
          x="1170"
          y="255"
          width="50"
          height="50"
          id="youtube"
        />
        <image
          class="img-fluid mt-3"
          href="@/assets/images/Instagram.svg"
          alt="Logo Instagram"
          x="1290"
          y="247"
          width="50"
          height="50"
          id="instagram"
        />
        <a href="mailto:info@copla.io" class="footerTextData" id="email">
          <text x="1600" y="190" id="email">info@copla.io</text>
        </a>
        <a href="tel:+34916244065" class="footerTextData" id="phone">
          <text x="1590" y="230" id="phone">+34916244065</text>
        </a>
        <image
          class="img-fluid mt-3"
          href="@/assets/images/logos_ayuda_2021.png"
          alt="Logos NextGenerationUE, INAEM y Plan de recuperación, Transformación y Resiliencia"
          x="500"
          y="510"
          id="image-aptent"
          style="height: 100px;"
        />
      </svg>
    </CContainer>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: -150px;
  z-index: 0;
}
.footerText {
  fill: white;
}
.footerTextData {
  fill: white;
  font-size: 1.5em;
}
@media only screen and (max-width: 1400px) {
  .footerTextData {
    fill: white;
    font-size: 1.8em;
  }
  #text-ministery {
    font-size: 1.3em;
    transform: translateX(-80px) translateY(10px);
  }
  #text-ministery2 {
    font-size: 1.3em;
    transform: translateX(-80px) translateY(25px);
  }
  #text-aptent {
    font-size: 1.3em;
    transform: translateX(-30px) translateY(10px);
  }
  #image-ministery {
    transform: translateY(20px) translateX(-80px);
  }
  #image-aptent {
    transform: translateY(15px);
  }
}
@media only screen and (max-width: 991px) {
  .footerTextData {
    fill: white;
    font-size: 2em;
  }
  #background {
    width: 100%;
  }
  #text-ministery {
    font-size: 1.6em;
    transform: translateX(-180px) translateY(20px);
  }
  #text-ministery2 {
    font-size: 1.6em;
    transform: translateX(-180px) translateY(35px);
  }
  #image-ministery {
    width: 700px;
    transform: translateX(-180px) translateY(35px);
  }
  #text-aptent {
    font-size: 1.6em;
    transform: translateX(-30px) translateY(20px);
  }
  #image-aptent {
    width: 230px;
    transform: translateX(-30px) translateY(35px);
  }
  #instagram {
    width: 70px;
    transform: translateX(10px) translateY(-5px);
  }
  #youtube {
    width: 70px;
    transform: translateX(-15px) translateY(0px);
  }
  #twitter {
    width: 70px;
    transform: translateX(-40px) translateY(0px);
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    margin-top: 0px;
  }
  .footerTextData {
    fill: white;
    font-size: 2.5em;
    transform: translateX(-120px);
  }
  #phone {
    transform: translateX(-60px) translateY(10px);
  }
  #text-ministery {
    font-size: 2em;
    transform: translateX(-180px) translateY(30px);
  }
  #text-ministery2 {
    font-size: 2em;
    transform: translateX(-180px) translateY(50px);
  }
  #image-ministery {
    width: 800px;
    transform: translateX(-180px) translateY(50px);
  }
  #text-aptent {
    font-size: 2em;
    transform: translateX(-30px) translateY(30px);
  }
  #image-aptent {
    width: 300px;
    transform: translateX(-30px) translateY(50px);
  }
  #background {
    height: 310px;
    width: 100%;
  }
  #instagram {
    width: 80px;
    transform: translateX(30px) translateY(-20px);
  }
  #youtube {
    width: 80px;
    transform: translateX(10px) translateY(-10px);
  }
  #twitter {
    width: 80px;
    transform: translateX(-10px) translateY(-10px);
  }
  #logo {
    transform: translateY(-50px) translateX(0px);
    width: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .footerTextData {
    fill: white;
    font-size: 3em;
    transform: translateX(-180px);
  }
  #text-ministery {
    font-size: 2.5em;
    transform: translateX(-350px) translateY(40px);
  }
  #text-ministery2 {
    font-size: 2.5em;
    transform: translateX(-350px) translateY(70px);
  }
  #text-aptent {
    font-size: 2.5em;
    transform: translateX(50px) translateY(40px);
  }
  #background {
    width: 100%;
    height: 57vw;
  }
  #image-ministery {
    width: 1050px;
    transform: translateX(-350px) translateY(75px);
  }
  #image-aptent {
    width: 400px;
    transform: translateX(50px) translateY(75px);
  }
  #instagram {
    width: 100px;
    transform: translateX(-30px) translateY(-30px);
  }
  #youtube {
    width: 100px;
    transform: translateX(-40px) translateY(-20px);
  }
  #twitter {
    width: 100px;
    transform: translateX(-50px) translateY(-20px);
  }
  #logo {
    transform: translateY(-50px) translateX(-10px);
    width: 300px;
  }
  #phone {
    transform: translateY(20px) translateX(-90px);
  }
}
.footer {
  padding: 0;
  background-color: white;
  border: 0;
}
.backgroundSVG {
  background-image: url('~@/assets/images/footer.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 824px;
}
</style>
