import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    user: null,
    roles: [],
    rol: {},
    update: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
      console.log(state)
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setUser(state, userData) {
      state.user = userData
    },
    setRol(state, rolesData) {
      let exists = false
      state.roles.forEach((element) => {
        if (element.id == rolesData.id) {
          exists = true
        }
      })
      if (!exists) {
        state.roles.push(rolesData)
      }
    },
    setActiveRol(state, rol) {
      state.roles.forEach((element) => {
        if (element.id == rol) {
          state.rol = element
        }
      })
    },
    emptyRol(state) {
      state.rol = {}
    },
    toogleUpdate(state) {
      state.update = !state.update
    },
  },
  getters: {
    authenticated(state) {
      return !!state.user
    },
    rolesCount(state) {
      return state.roles.length
    },
    rol(state) {
      return state.rol
    },
    update(state) {
      return state.update
    },
  },
  actions: {},
  modules: {},
})
