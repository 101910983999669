<template>
  <CDropdown>
    <CDropdownToggle aria-label="Cambiar idioma de la página"
      ><span id="currentLanguage" :class="flag"></span
    ></CDropdownToggle>
    <CDropdownMenu>
      <CDropdownItem
        v-for="locale in flags"
        :key="locale"
        @click="changeLanguage(locale)"
        ><span class="me-2" :class="'fi fi-' + locale"></span
        >{{ languageName(locale) }}</CDropdownItem
      >
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
export default {
  name: 'AppLocaleChanger',
}
</script>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
var languages = require('@cospired/i18n-iso-languages')
var i18n = useI18n({})
var flag = ref('fi fi-' + i18n.locale.value)
const flags = ref([])
const changeLanguage = (locale) => {
  if (locale == 'gb') {
    i18n.locale.value = 'en'
  } else if (locale == 'es-ct') {
    i18n.locale.value = 'ca'
  } else {
    i18n.locale.value = locale
  }
  flag.value = 'fi fi-' + locale
  document.documentElement.setAttribute("lang",i18n.locale.value)
  loadFlags()
}
const languageName = (locale) => {
  var lang = locale
  if (locale == 'gb') {
    lang = 'en'
  } else if (locale == 'es-ct') {
    lang = 'ca'
  }
  if (lang == 'ca') {
    return languages.getName('ca', 'es')
  } else {
    return languages.getName(lang, lang)
  }
}
const loadFlags = () => {
  flags.value = []
  var locales = i18n.availableLocales
  locales.forEach((value) => {
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/' + value + '.json'),
    )
    if (value != i18n.locale.value) {
      if (value == 'en') {
        flags.value.push('gb')
      } else if (value == 'ca') {
        flags.value.push('es-ct')
      } else {
        flags.value.push(value)
      }
    }
  })
}
onBeforeMount(() => {
  loadFlags()
})
</script>

<style lang="scss" scoped>
#currentLanguage {
  width: 32px;
  height: 24px;
}
</style>
