<template>
  <div
    class="avatar avatar-md"
    v-if="authenticated"
  >
    <img
      :src="user.picture"
      class="avatar-img"
      alt="Avatar del usuario"
    /><span class="avatar-status bg-success"></span>
  </div>
</template>

<script>
export default {
  name: 'AppHeaderDropdownAccnt',
}
</script>

<script setup>
import { useStore } from 'vuex'
//import { ref } from 'vue'
import { computed } from 'vue'

const store = useStore()

let authenticated = computed(() => {
  return store.getters.authenticated
})
let user = computed(() => {
  return store.state.user
})
</script>
