import router from '@/router'
import { createAuth0Client } from '@auth0/auth0-spa-js'
import { computed } from 'vue'
import { useStore } from 'vuex'
import useOrganization from '@/hooks/useOrganization.js'

import config from '../../auth_config'

function useAuth(baseURL) {
  const store = useStore()
  const organization = useOrganization(baseURL)

  let authenticated = computed(function () {
    return store.getters.authenticated
  })

  let user = computed(function () {
    return store.state.user
  })

  async function buildAuth0Client() {
    const client = await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
      cacheLocation: 'localstorage'
    })
    return client
  }

  async function login(client) {
    console.log("Cliente")
    console.log(client)
    try {
      await client.getTokenSilently();
      let userData = await client.getUser()
      console.log(userData)
      store.commit('setUser', userData)
      localStorage.setItem('user', JSON.stringify(userData))
      let roles = await organization.getRoles(userData.sub)
      if (roles.length == 0) {
        router.push('/organization/new')
      } else {
        router.push('/sessions') //Change to Services when ready
      }
    } catch (e) {
      router.push('/')
      console.error(e)
    }
  }

  async function loginButton(client) {
    try {
      await client.loginWithPopup({});
      let userData = await client.getUser()
      store.commit('setUser', userData)
      localStorage.setItem('user', JSON.stringify(userData))
      let roles = await organization.getRoles(userData.sub)
      if (roles.length == 0) {
        router.push('/organization/new')
      } else {
        router.push('/sessions') //Change to Services when ready
      }
    } catch (e) {
      router.push('/')
      console.error(e)
    }
  }

  function logout(client) {
    localStorage.clear()
    client.logout({
      returnTo: 'https://copla.io',
    })
  }

  return {
    authenticated,
    user,
    buildAuth0Client,
    login,
    loginButton,
    logout,
  }
}

export default useAuth
